import { useBlockNumber, useEthers } from "@usedapp/core";
import { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { formatUnits } from '@ethersproject/units'
import Web3Connect from "components/web3-connect/Web3Connect";
import { useLockTimeout, useMint5000 } from "hooks/hooks";

const yourTokenContractAddress = '0xaA04C5624c553e24ccAC1651925E88616F29eDf7'

type LeftTimeout = {
  min: number;
  sec: number;
  fullSec: number;
}

const Home: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(false)
  const [buttonText, setButtonText] = useState<string>("Get 5000 7BCPLN")
  const [leftTimeout, setLeftTimeout] = useState<LeftTimeout>({ min: 0, sec: -1, fullSec: -1 })
  const [blockNumber, setBlockNumber] = useState<number | undefined>(0)
  const { account, deactivate } = useEthers()
  const { tokenBalance, sendMint5000, stateMint5000 } = useMint5000(yourTokenContractAddress, account);
  const { lockTimeout } = useLockTimeout(yourTokenContractAddress, account);
  const currentBlockNumber = useBlockNumber()

  useEffect(() => {
    const key = stateMint5000?.status
    console.log("state of transaction:", key);
    if (key === 'None') { setButtonText('Get 5000 7BCPLN') }
    if (key === "Fail" || key === "Exception") {
      setLoading(false)
      setButtonText('Approval Denied')
      setTimeout(() => {
        setButtonText('Get 5000 7BCPLN')
      }, 2000)
    }
  }, [blockNumber, currentBlockNumber, loading, stateMint5000])

  const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);

  const nowShort = () => {
    const thisMoment = Date.now().toString()
    return Number(thisMoment.slice(0, 10))
  }

  const handleClick = async () => {
    setLoading(true)
    setButtonText('Awaiting Server')
    account && sendMint5000(account)
  }

  useEffect(() => {
    const timeDiff = lockTimeout - nowShort();

    if (timeDiff >= 0 && !disable) {
      setLoading(false)
      if (leftTimeout.fullSec) {
        setLeftTimeout(
          {
            min: Math.floor((lockTimeout - nowShort()) / 60),
            sec: (lockTimeout - nowShort()) % 60,
            fullSec: lockTimeout - nowShort()
          });
      }
      clearInterval(intervalRef.current as NodeJS.Timeout);
      intervalRef.current = setInterval(() => {
        setLeftTimeout(
          {
            min: Math.floor((lockTimeout - nowShort()) / 60),
            sec: (lockTimeout - nowShort()) % 60,
            fullSec: lockTimeout - nowShort()
          });
      }, 1000);
      setDisable(true);
    }

    if (leftTimeout.fullSec < 0 && disable) {
      clearInterval(intervalRef.current as NodeJS.Timeout);
      setBlockNumber(currentBlockNumber)
      console.log("block number:", blockNumber);
      console.log("current block number:", currentBlockNumber);
      if (blockNumber && currentBlockNumber && (blockNumber < currentBlockNumber)) {
        setDisable(false);
        setButtonText('Get 5000 7BCPLN')
      }
    }
  }, [disable, lockTimeout, leftTimeout, currentBlockNumber, blockNumber])

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current as NodeJS.Timeout);
    }
  }, [])

  return (
    <Box sx={{ flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant='h1' component='h1' >7BCPLN</Typography>
      {account && tokenBalance ? (
        <>
          <Typography sx={{ margin: '0 0 2rem' }} variant='h5' component='p'>Your current 7BCPLN balance: {formatUnits(tokenBalance, 18)} 7BCPLN</Typography>
          <LoadingButton
            color="primary"
            onClick={handleClick}
            loading={loading}
            variant="contained"
            disabled={disable}
            loadingPosition="start"
            startIcon={<SendIcon />}
          >
            {
              disable ?
                (leftTimeout.min < 0) ?
                  `Awaiting for new block`
                  :
                  `Lock: ${leftTimeout.min < 10 ? String("0" + leftTimeout.min) : leftTimeout.min}:${leftTimeout.sec < 10 ? String("0" + leftTimeout.sec) : leftTimeout.sec}`
                : buttonText
            }
          </LoadingButton>
        </>
      ) : (
        <>
          <Typography sx={{ margin: '0 0 2rem' }} variant='h5' component='p'>Please connect your web3 wallet in order to see your 7BCPLN balance.</Typography>
          <Web3Connect account={account} handleDeactivate={() => deactivate()} />
        </>
      )}
    </Box>
  );
}
export default Home;