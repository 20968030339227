import { Button, Dialog, DialogActions, DialogTitle, Divider } from "@mui/material"


type WalletConnectionType = 'Metamask' | 'WalletConnect'
type Web3ModalProp = {
  open: boolean,
  handleClose: () => void,
  connectToWallet: (value: WalletConnectionType) => void
}


const Web3Modal: React.FC<Web3ModalProp> = ({ open, handleClose, connectToWallet }) => {

  return (
    <Dialog onClose={handleClose} open={open}>

      <DialogTitle>How do you want to connect</DialogTitle>

      <Divider />

      <Button onClick={() => connectToWallet('Metamask')} >Metamask</Button>
      <Button onClick={() => connectToWallet('WalletConnect')} >WalletConnect</Button>

      <Divider />

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>

    </Dialog>
  )
}

export default Web3Modal;
