import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from 'views/Home';
import PageTemplate from 'templates/PageTemplate';
import { CssBaseline } from '@mui/material';
import { DAppProvider, Config, Ropsten } from '@usedapp/core'

const config: Config = {
  readOnlyChainId: Ropsten.chainId,
  readOnlyUrls: {
    [Ropsten.chainId]: window.__APP_CONFIG__.rpcUrl
  }
}

const App: React.FC = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <DAppProvider config={config}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={
              <PageTemplate>
                <Home />
              </PageTemplate>
            } />
          </Routes>
        </BrowserRouter>
      </DAppProvider>
    </React.Fragment>
  );
}

export default App;
