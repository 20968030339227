import { Box, Button, Typography } from "@mui/material";
import { useEthers, shortenAddress } from '@usedapp/core'
import { useState } from "react";
import Web3Modal from "components/web3-connect/Web3Modal";
import { walletconnect } from "const/connectors/connectors";


type Web3ConnectProp = {
  account: string | null | undefined,
  handleDeactivate: () => void
}

const Web3Connect: React.FC<Web3ConnectProp> = ({ account, handleDeactivate }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { activateBrowserWallet, activate } = useEthers()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConnectToWallet = async (value: string) => {
    switch (value) {
      case "WalletConnect":
        activate(walletconnect)
          .catch(err => console.error(err))
        handleClose()
        break;
      case "Metamask":
        activateBrowserWallet()
        handleClose()
        break;
      default:
        break;
    }
  }

  return (
    <Box >

      {!account &&
        <Button variant="outlined" onClick={handleClickOpen}>
          Connect
        </Button>
      }
      {account &&
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ margin: '0 1rem 0 0', color: 'white' }} variant="h5" component="h5" >
            {shortenAddress(account)}
          </Typography>
          <Button onClick={handleDeactivate}>Disconnect</Button>
        </Box>
      }

      <Web3Modal
        open={open}
        handleClose={handleClose}
        connectToWallet={handleConnectToWallet}
      />
    </Box>
  );
}
export default Web3Connect;