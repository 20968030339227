import { useContractCall, useContractFunction, useEthers, TransactionStatus } from "@usedapp/core";
import yourTokenAbi from 'contracts/hardhat_contracts.json';
import { Contract } from '@ethersproject/contracts'
import { utils } from 'ethers'
import { Falsy } from "@usedapp/core/dist/esm/src/model/types";

type Mint5000 = {
  tokenBalance: string;
  sendMint5000: (account: string) => void
  eventsMint5000: utils.LogDescription[] | undefined
  stateMint5000: TransactionStatus
  resetMint: () => void
}
type LockTimeout = {
  lockTimeout: number
  sendLockTimeout: (account: string) => void
  eventsLockTimeout: utils.LogDescription[] | undefined
  stateLockTimeout: TransactionStatus
  resetLockTimeout: () => void
}

const yourTokenInterface = new utils.Interface(yourTokenAbi[31337].localhost.contracts.YourToken.abi)
export const useMint5000 = (tokenAddress: string | Falsy, address: string | Falsy): Mint5000 => {
  const { account } = useEthers()
  const contractAddress = tokenAddress ? tokenAddress : ""

  if (contractAddress === "") {
    console.log("add tokenAddress")
  }

  const contract = new Contract(contractAddress, yourTokenInterface)
  const { state: stateMint5000, send: sendMint5000, events: eventsMint5000, resetState: resetMint } = useContractFunction(contract, `mint5000`)
  const [tokenBalance] = useContractCall(
    account &&
    address &&
    tokenAddress && {
      abi: yourTokenInterface,
      address: tokenAddress,
      method: "balanceOf",
      args: [address],
    }
  ) ?? [];
  return { tokenBalance, sendMint5000, eventsMint5000, stateMint5000, resetMint };
}

export const useLockTimeout = (tokenAddress: string | Falsy, address: string | Falsy): LockTimeout => {
  const contractAddress = tokenAddress ? tokenAddress : ""
  const { account } = useEthers()
  if (contractAddress === "") console.log("add tokenAddress");

  const contract = new Contract(contractAddress, yourTokenInterface)
  const { state: stateLockTimeout, send: sendLockTimeout, events: eventsLockTimeout, resetState: resetLockTimeout } = useContractFunction(contract, `getLockTimeout`)
  const [bigNumLockTimeout] = useContractCall(
    account &&
    tokenAddress &&
    {
      abi: yourTokenInterface,
      address: tokenAddress,
      method: "getLockTimeout",
      args: [address],
    }
  ) ?? [];
  const lockTimeout = bigNumLockTimeout?.toNumber()
  return { lockTimeout, sendLockTimeout, eventsLockTimeout, stateLockTimeout, resetLockTimeout }
}
