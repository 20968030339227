import { Box, Typography } from "@mui/material";
import Web3Connect from "components/web3-connect/Web3Connect";
import { useEthers } from '@usedapp/core';

const Navbar: React.FC = () => {
  const { account, deactivate } = useEthers()

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "1rem 2rem", width: "100%", boxShadow: '0 0 20px 15px rgb(71, 122, 255)', backgroundColor: 'rgb(20, 30, 56)' }}>

      <Box sx={{ display: "flex", alignItems: 'end' }}>
        <Typography sx={{ fontWeight: 'bold', color: 'white' }} variant="h3" component="h1">7BCPLN Faucet</Typography>
      </Box>

      <Box sx={{ display: "flex", gap: 2, alignItems: 'end', margin: '1rem 0' }}>
        <Web3Connect account={account} handleDeactivate={() => deactivate()} />
      </Box>
    </Box>
  )
}

export default Navbar;