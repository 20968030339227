import { Box } from "@mui/material";
import Navbar from "components/navbar/Navbar";

const PageTemplate: React.FC = ({ children }) => (
  <Box sx={{
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }}>
    <Navbar />
    {children}
  </Box>
);

export default PageTemplate;